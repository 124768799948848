import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ercLogo from "../images/erc-logo.svg";
import euroflowLogo from "../images/euroflow-logo.svg";
import lumcLogo from "../images/lumc-logo.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`timascan`, `ERC`, `cancer`, `macrophages`]}
        title="Home"
      />

      <section>
        <p>This is the official homepage of the TiMaScan project.</p>

        <h2 className="text-2xl font-bold inline-block my-4">
          ERC Advanced Grant for research on monitoring of cancer via tissue
          macrophages
        </h2>

        <div className="md:flex content-center">
          <p className="leading-relaxed flex-shrink">
            The TiMaScan project is supported by the EU through{" "}
            <b>
              European Research Council (ERC) Advanced Grant{" "}
              <a
                href="https://cordis.europa.eu/project/id/695655"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700 hover:text-blue-800"
              >
                ERC-2015-AdG-695655
              </a>
            </b>{" "}
            entitled Recirculated tissue macrophages (TiMa) in blood: Novel
            approach to early diagnosis and treatment monitoring in oncology.
            This ERC Advanced Grant was awarded to Principle Investigator Prof.
            J.J.M. van Dongen, MD, PhD in 2016.
          </p>
          <div className="md:w-1/3 md:p-0 sm:px-32 px-8 md:flex-shrink-0 md:ml-6 md:my-0 my-8 md:flex-none flex justify-center">
            <img alt="ERC logo" className="w-full h-32" src={ercLogo} />
          </div>
        </div>

        <h2 className="text-2xl font-bold inline-block my-4">Aim</h2>

        <p className="leading-relaxed">
          The aim of the TiMaScan project is to perform basic research on the
          function of the monocyte-macrophage system, and to use this
          fundamental knowledge for the development of improved,
          minimally-invasive diagnostic tools for early diagnosis of cancer, as
          well as monitoring of treatment and disease progression in oncology.
          Read more about the concept and aims of the TiMaScan project{" "}
          <Link to="/goals" className="text-blue-700">
            here
          </Link>
          .
        </p>

        <h2 className="text-2xl font-bold inline-block my-4">Achievements</h2>

        <p className="leading-relaxed">
          One of the main findings of the TiMaScan project so far is that a
          monocyte-specific antibody panel for flow cytometry can be used to
          show that the numbers of different monocyte subsets in humans,
          including classical, intermediate and non-classical monocytes, differ
          depending on age and tissue. To learn more about the results of the
          TiMaScan project, go to the{" "}
          <Link to="/achievements" className="text-blue-700">
            Achievements page
          </Link>
          .
        </p>

        <h2 className="text-2xl font-bold inline-block my-4">Team</h2>

        <p className="leading-relaxed">
          The research is performed by the{" "}
          <Link to="/team" className="text-blue-700">
            TiMaScan Research team
          </Link>{" "}
          of the{" "}
          <a
            href="https://immunology.lumc.nl/research-58"
            rel="noreferrer noopener"
            target="_blank"
            className="text-blue-700 hover:text-blue-800"
          >
            Immune monitoring group
          </a>{" "}
          of Prof. Van Dongen at the Dept. of Immunology, Leiden University Medical Center (LUMC) in Leiden, The
          Netherlands, in close collaboration with local partners (e.g. Depts.
          of Internal Medicine, Surgery, Pathology, Cardiology, Pediatrics),
          national partners (e.g. Erasmus Medical Center, Martini Hospital
          Groningen), international partners (e.g. University of Salamanca,
          Spain), and the{" "}
          <a
            href="https://euroflow.org"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
          >
            EuroFlow Consortium
          </a>
          .
        </p>

        <div className="md:flex justify-between my-10">
          <div className="md:flex-none flex justify-center">
            <img alt="ERC logo" className="w-auto h-20" src={ercLogo} />
          </div>
          <div className="md:flex-none flex justify-center md:m-0 mt-10">
            <img alt="LUMC logo" className="h-20 w-auto" src={lumcLogo} />
          </div>
          <div className="md:flex-none flex justify-center md:m-0 mt-10">
            <img alt="EuroFlow logo" className="h-20 w-auto" src={euroflowLogo} />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
